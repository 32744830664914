import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Cta from "../components/cta";
import * as Styles from "../styles/effect.module.css";

const Infrastructure = () => {
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          Infrastructure - KATSANA® Integrated Fleet Management & Industrial
          Operation Solutions
        </title>
        <meta
          name="description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.katsana.com/infrastructure/" />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.katsana.com/infrastructure/"
        />
        <meta
          property="og:title"
          content="Infrastructure - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
        />
        <meta
          property="og:description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0  md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 max-w-6xl mx-auto ">
          <h3 className="pt-24 md:pt-12 text-lg md:text-2xl text-bluegray">
            A brief overview on KATSANA's Infrastructure.
          </h3>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            Infrastructure
          </h1>
        </div>
      </div>

      <div className=" bg-gradient-to-b from-[hsla(204,26%,93%,1)] to-white relative pb-36">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
            }
          ></div>
        </div>
        <div className="max-w-4xl p-8 xl:p-16 mx-auto bg-gradient-to-b from-[hsla(204,26%,93%,0)] to-[hsla(204,26%,99%,0)] via-white text-gray-800  relative">
          <h4 className="text-xl font-medium">Background</h4>
          <p className="mt-6 leading-7">
            This document serves as an overview on KATSANA Infrastructure,
            handling large-scale processes daily. The infrastructure is built
            from ground up to scale, and encapsulates the best of our know-how
            and experience since the beginning of KATSANA.
            <br />
            <br />
            KATSANA services run on Amazon Web Services (AWS) at the Singapore
            datacenter (ap-southeast-1).
          </p>

          <h4 className="mt-6 text-xl font-medium">Security – Application</h4>

          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              HTTPS on all services, including API calls – Full SSL with Grade A
              certified implementation. RSA 2048-bits, SHA 256 with RSA
              certificate.
            </li>
            <li>
              Laravel framework has implemented good security measure, with code
              that is always reviewed by the community.
            </li>
            <li>Login throttles for consecutive login failures.</li>
            <li>Bcrypt-hashed password storage.</li>
            <li>Cookies stored with AES-256-CBC encryption/cypher.</li>
            <li>All requests are secured with CSRF token</li>
          </ul>

          <h4 className="mt-6 text-xl font-medium">Security – Servers</h4>

          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              AWS Security Groups firewall on all server instances and as well
              as IAM roles where each server only allowed to do what it supposed
              to do.
            </li>
            <li>
              Locked down server login using key and limited to only CTO &
              DevOps. No engineer nor company personnel has access to the
              production server.
            </li>
            <li>
              Closed unused ports and restrict direct access to certain IPs •
              Automated and manual security updates that immediately patch
              exploits / vulnerability.
            </li>
            <li>
              Logs are shipped to log server and monitored for suspicious
              activities
            </li>
          </ul>

          <h4 className="mt-6 text-xl font-medium">Uptime</h4>
          <p className="mt-6 leading-7">
            KATSANA Platform offers 99.5% uptime.
            <br />
            <br />
            Note: While KATSANA Platform is highly redundant with high
            availability, it is heavily reliant on the availability of GSM
            connectivity and coverage. Network disruption on the telco side is
            bound to affect the transmission of location data from vehicle to
            the Infrastructure. As this is outside of our direct control,
            KATSANA is unable to provide a service warranty for telco
            connectivity.
          </p>
          <StaticImage
            alt="katsana"
            src="../../static/images/graphics/infrastructure.jpeg"
            objectFit="contain"
            className="mt-6"
          />
          <p className="mt-6 leading-7">
            Note: ‘KATSANA web’ above refers to https://www.katsana.com website
            which runs separately from KATSANA Platform. KATSANA Platforms
            typically performs at 99.95% or better availability.
          </p>

          <h4 className="mt-6 text-xl font-medium">Backup – Database</h4>
          <p className="mt-6 leading-7">
            KATSANA runs full daily backup during low traffic hours at midnight
            to avoid any disruption to our service.
            <br />
            <br />
            Backup are compressed and encrypted with AES-256 encryption and
            stored in AWS S3 bucket.
            <br />
            <br />
            Full backup are kept for 90 days before being securely erased.
          </p>

          <h4 className="mt-6 text-xl font-medium">Backup – Code</h4>
          <p className="mt-6 leading-7">
            Source code are hosted at Github.
            <br />
            <br />
            Access to code are controlled and limited to staff that has
            permission.
          </p>

          <h4 className="mt-6 text-xl font-medium">
            Disaster Recovery – Database
          </h4>
          <p className="mt-6 leading-7">
            In the event of disaster events such as database server crash, we
            have dedicated slave database server that can be promoted to be
            master in a matter of minutes. If in the worst case both main and
            slave database server crash and cannot be recover, KATSANA can
            restore the database using the full backup that we run daily.
          </p>

          <h4 className="mt-6 text-xl font-medium">
            Disaster Recovery – App Server
          </h4>
          <p className="mt-6 leading-7">
            All our app server use autoscale technology which builds from an
            image that we constantly create every time a new version is
            released. In the event of an app server crash, a new server instance
            will be automatically launched using the latest image to replace the
            crashed server.
          </p>
        </div>
      </div>
      <Cta />
    </Layout>
  );
};

export default Infrastructure;
